var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-table", {
    ref: "table",
    attrs: {
      title: "위험성평가 시나리오 종합 목록",
      columns: _vm.gridColumns,
      data: _vm.summary.data,
      merge: _vm.gridMerge,
      gridHeight: _vm.grid.height,
    },
    scopedSlots: _vm._u([
      {
        key: "customArea",
        fn: function ({ props, col }) {
          return [
            col.name === "customCol"
              ? [
                  _c(_vm.imprComponent, {
                    tag: "component",
                    attrs: {
                      col: col,
                      props: props,
                      inputEditable: false,
                      requestContentsCols: _vm.imprProps.requestContentsCols,
                      tableKey: _vm.imprProps.tableKey,
                      ibmTaskTypeCd: _vm.imprProps.ibmTaskTypeCd,
                    },
                  }),
                ]
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }